import { link } from "fs";
import { Label } from "../opportunity/OpportunityInfoHelper";

export const navItems = {
  links: [
    {
      link: "/news",
      label: "News",
    },
    {
      link: "/blog",
      label: "Blog",
    },
    {
      link: "/jobs",
      label: "Jobs",
    },
    {
      link: "/competitions",
      label: "Competitions",
    },
    {
      link: "/festivals",
      label: "Festivals",
    },
    {
      link: "/concerts",
      label: "Concerts",
    },
    {
      link: "/compositions",
      label: "Compositions",
    },
    {
      link: "/researchpapers",
      label: "Research Papers"
    },
  ],
};
